<template>
  <main id="gardner-results">
    <section class="content">
      <section class="intro">
          <h1 class="main">Resultat</h1>
      </section>
      <article class="floating">
        <h1>Ditt resultat på Personlighetstestet</h1>
        <profilegardner />
        <section class="potential" v-for="(potential, index) in potentials" :key="index">
            <h2>{{potential.title}}</h2>
            <p>Du lär dig bäst genom att:</p>
            <ul>
                <li v-for="(item, x) in potential.learning" :key="x">{{ item }}</li>
            </ul>
            <p>Du har lätt för att:</p>
            <ul>
                <li v-for="(item, y) in potential.easy" :key="y">{{ item }}</li>
            </ul>
            <p>Passande yrken är:</p>
            <ul>
                <li v-for="(item, z) in potential.occupations" :key="z">{{ item }}</li>
            </ul>
        </section>
        
        <aside class="inline-menu">
          <router-link to="/test">Fler test!</router-link>
          <router-link to="/studieprofil">Till min studieprofil</router-link>
        </aside>
      </article>
    </section>
  </main>
</template>

<script>
import profilegardner from '@/components/profiles/GardnerProfile';

export default {
  name: 'gardnerresults',
  data(){
      return {
          threshold: 20
      }
  },
  components: {
      profilegardner
  },
  computed: {
      potentials(){
          return this.$store.getters.gardner.potentials;
      },
      profile(){
          return this.$store.state.profile.gardner;
      }
  }
}
</script>

<style lang="scss">
@import '../../../scss/variables';

#gardner-results {
    
    .content {

        .intro {
            position: relative;

            aside {
                background: black;
                position: absolute;
                top: 1rem;
                left: 1rem;
                padding: .25rem .5rem;
                border-radius: $radius;
            }
        }

        .floating {

            .potential {
                p {
                    margin: .5rem 0;
                    font-weight: 700;
                }

                ul {
                    margin: 0rem 0 2rem 2rem;

                    li {
                        font-size: .8rem;
                        font-style: italic;
                        margin-bottom: .25rem;
                    }
                }

            }

        }
    }

}

</style>

